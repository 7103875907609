import {
    GET,
    db
} from '@/firebase.js'

import Badge from '@/components/Badge/Badge.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Loading from '@/components/Loading/Loading.vue'
import Mat from '@/components/Mat/Mat.vue'
import Gad from '@/components/Gad/Gad.vue'
import Skeleton from '@/components/Skeleton/Skeleton.vue'

export default {
    name: 'Badges',
    components: {
        Badge,
        Tabs,
        Loading,
        Mat,
        Gad,
        Skeleton
    },
    data: function () {
        return {
            badges: [],
            lastVisible: null,
            typeFilter: 'All',
            typeFilters: ['All', 'Social', 'Planet'],
            allBadgesFetched: false,
            queryBusy: false,
            firstQueryAfterReset: true
        }
    },
    methods: {
        resetQuery: function () {
            this.lastVisible = null
            this.badges = []
            this.allBadgesFetched = false
            this.queryBusy = false
            this.firstQueryAfterReset = true
        },
        setTypeFilter: function (filter) {
            this.typeFilter = filter
            this.resetQuery()
            this.getBadges()
        },
        getBadges: function () {
            if (this.allBadgesFetched || this.queryBusy || !this.$event.badges) return
            this.queryBusy = true
            let col = db.collection('badges')
            col = col.limit(4)
            if (this.lastVisible) col = col.startAfter(this.lastVisible)
            let filter = this.typeFilter
            if (filter === 'Planet') filter = 'Environment'
            if (filter !== 'All') col = col.where('type', '==', filter)
            if (this.firstQueryAfterReset && this.$event.badges) {
                col = col.where('__name__', 'in', this.$event.badges.slice(0, 10))
                this.firstQueryAfterReset = false
            }
            GET(col).then(badges => {
                this.lastVisible = badges.docs[badges.docs.length - 1]
                if (!this.lastVisible) {
                    console.log('All badges fetched')
                    return this.allBadgesFetched = true
                }
                this.queryBusy = false
                badges.forEach(badge => {
                    if (this.badges.find(badgeChild => badgeChild.id === badge.id)) return
                    this.badges.push({
                        title: badge.data().title,
                        icon: badge.data().icon,
                        type: badge.data().type,
                        desc: badge.data().short_desc,
                        id: badge.id,
                        orgs: []
                    })
                })
                setTimeout(() => {
                    this.badges.loaded = true
                }, 1000)
            })
        },
        getOrg: function (orgId) {
            return new Promise(resolve => {
                let doc = db.collection('orgs').doc(orgId)
                GET(doc).then(org => {
                    resolve({
                        name: org.data().name,
                        logo: org.data().logo,
                        id: org.id,
                        events: org.data().events
                    })
                })
            })
        }
    },
    watch: {
        '$event.badges': function () {
            this.getBadges()
        }
    },
    created: function () {
        if (this.$event.badges) {
            console.log('Fetching badges')
            this.getBadges()
        }
    }
}