var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-11"},[_c('img',{staticClass:"view-corner-illu badges",attrs:{"src":require("@/assets/img/BadgesView/badges-corner-illu.svg"),"alt":""}}),_vm._m(0),_c('Tabs',{staticClass:"mx-5 mb-n2 fade-up-ani anim-2",attrs:{"updateCB":_vm.setTypeFilter,"activeTab":_vm.typeFilter,"tabs":_vm.typeFilters}}),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.getBadges),expression:"getBadges"}],attrs:{"infinite-scroll-disabled":"queryBusy","infinite-scroll-distance":"10"}},[_vm._l((_vm.badges),function(badge,index){return _c('div',{key:index},[_c('Badge',{staticClass:"mx-auto d-block mt-5",attrs:{"type":badge.type,"icon":badge.icon,"title":badge.title,"desc":badge.desc,"id":badge.id,"size":"large"}}),(
           index === 2 &&
           _vm.badges.loaded &&
           _vm.$event.featuredOrgs &&
           _vm.$event.featuredOrgs.badges1
         )?_c('Gad',{staticClass:"mt-8",attrs:{"type":"featCom","oid":"accenture","dbRef":_vm.$event.featuredOrgs.badges1}}):_vm._e(),(
           index === 2 &&
           _vm.badges.loaded &&
           (!_vm.$event.featuredOrgs || !_vm.$event.featuredOrgs.badges1) &&
           !_vm.$user.uid
         )?_c('Gad',{attrs:{"type":"join"}}):_vm._e(),(
           (index === 17 ||
             (_vm.badges.length < 17 && index === _vm.badges.length - 1)) &&
           _vm.badges.loaded &&
           _vm.$event.featuredOrgs &&
           _vm.$event.featuredOrgs.badges2
         )?_c('Gad',{staticClass:"mt-8",attrs:{"dbRef":_vm.$event.featuredOrgs.badges2,"type":"posCom","oid":"rise"}}):_vm._e()],1)}),((_vm.queryBusy && !_vm.allBadgesFetched) || _vm.badges.length === 0)?_c('Skeleton',{staticClass:"mt-5",attrs:{"type":"Badge"}}):_vm._e()],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-top-banner badges"},[_c('div',{staticClass:"view-top-banner-text-box"},[_c('h1',{staticClass:"fade-up-ani view-header"},[_vm._v("Badges")])])])}]

export { render, staticRenderFns }